import { types } from 'mobx-state-tree'
import * as views from './guiStoreViews'
import * as actions from './guiStoreActions'
import { INTERFACE_TYPES, PRESET_TABS, TABS } from '../../../constants/status'
import Product from '../../models/product/Product'

const GuiStore = types
  .model('guiStore', {
    presetsModal: false,
    uploadModal: false,
    uploadOnboardingModal: false,
    uploadInfoModal: false,
    paywallModal: false,
    generatingModal: false,
    mergeAccountsModal: false,
    productModal: false,
    tokensModal: false,
    checkoutModal: false,
    productsModal: false,
    settingsModal: false,
    savePresetModal: false,
    animateModal: false,
    editTemplateModal: false,
    helpModal: false,
    productSettingsModal: false,

    productModalItem: types.maybeNull(types.reference(Product)),

    onboardingShown: false,

    paywallTitle: types.maybeNull(types.string),

    presetListTab: types.optional(
      types.enumeration('presetListTab', [PRESET_TABS.PRIVATE, PRESET_TABS.PUBLIC]),
      PRESET_TABS.PUBLIC
    ),
    selectedTab: types.optional(
      types.enumeration('selectedTab', Object.values(TABS)),
      TABS.NONE
    ),

    // extra data
    extraData: types.frozen(),

    // glows
    glowBorder: false,
    templateGlow: false,

    productType: INTERFACE_TYPES.LIFESTYLE,
    launchUploadProduct: false,

  })
  .views(views.guiStoreViews)
  .actions(actions.guiStoreActions)

export default GuiStore