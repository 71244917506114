import { types } from "mobx-state-tree"
import * as actions from './UserActions'
import * as views from './UserViews'

const User = types
  .model('User', {
    id: types.identifierNumber,
    hashId: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
  })
  .views(views.UserViews)
  .actions(actions.UserActions)

export default User