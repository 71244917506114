import { types } from "mobx-state-tree"
import * as views from './ProductViews'
import * as actions from './ProductActions'
import Asset from "../asset/Asset"

const Product = types
  .model('Product', {
    id: types.identifierNumber,
    productType: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    title: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    sku: types.maybeNull(types.string),
    asset: types.maybeNull(
      types.reference(Asset)),
    tempType: types.maybeNull(types.string),
    guessedType: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    triggerLora: types.maybeNull(types.string),
    coverUrl: types.maybeNull(types.string),
    lowResCoverUrl: types.maybeNull(types.string)
  })
  .views(views.productViews)
  .actions(actions.productActions)

export default Product