import { inject, observer } from 'mobx-react'
import React, { useEffect } from 'react'
import LoaderPage from '../../content/pages/LoaderPage/LoaderPage'
import { useAuth0 } from '@auth0/auth0-react'
import useQuery from '../../hooks/useQuery'
import { setQueryStrings } from '../../services/query-service'

const LoaderWrapper = (props) => {

  const { isLoading, isAuthenticated } = useAuth0()

  const queries = useQuery()

  useEffect(() => {
    if (props.store.hydrated) {
      setTimeout(() => {
        setQueryStrings(queries, props.store.queryStore.set)
      }, 1)
    }
  }, [ queries, props.store.hydrated ]) 

  useEffect(() => {
    // if (isLoading) {
    //   props.store.set('loadingScreen', true)
    // }
    if (!isAuthenticated && !isLoading && props.store.hydrated) {
      setTimeout(() => {
        props.store.set('loadingScreen', false)
      }, 1)
      
    }
  }, [isLoading, isAuthenticated, props.store.hydrated])


  // if (props.store.loadingScreen) {
  //   return 
  // }


  return <>
    <LoaderPage loadingScreen={props.store.loadingScreen}></LoaderPage>
    {props.store.loadingScreen ? null : props.children}
  </>
}

export default inject('store')(observer(LoaderWrapper))