import { types } from "mobx-state-tree"
import * as actions from './assetsFactoryActions'
import * as views from './assetsFactoryViews'
import Asset from "../../models/asset/Asset"

const AssetFactory = types
  .model('AssetFactory', {
    assets: types.optional(
      types.array(Asset), []
    )
  })
  .views(views.assetsFactoryViews)
  .actions(actions.assetsFactoryActions)

export default AssetFactory