import find from 'lodash/find'
import { _add_update_objects } from '../../../services/factories-service'


export const jobsFactoryActions = self => ({
  addUpdateJobs(jobs) {
    return _add_update_objects(jobs, self.jobs, self.parseJob)
  },
  addUpdateJob(job) {
    const ids = self.addUpdateJobs([job])
    return ids[0]
  },
  getJob(id) {
    return find(self.jobs, u => u.id === id)
  },
  parseJob(p) {
    return ({
      ...p,
      generatedAsset: p.generatedAsset?.id ? self.assetsFactory.addUpdateAsset(p.generatedAsset) : null,
      upscaledAsset: p.upscaledAsset?.id ? self.assetsFactory.addUpdateAsset(p.upscaledAsset) : null,
    })
  },
  set(key, value) {
    self[key] = value
  }
})

