
export const UserViews = self => ({
  get fullName() {
    if (self.firstName !== null ) {
      return self.firstName + ' ' + self.lastName 
    } else {
      return true
    }
  },

})