import React from 'react'
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../constants/system';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);


const Checkout = (props) => {
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{clientSecret: props.clientSecret}}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  )
}

export default Checkout