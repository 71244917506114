import { types } from "mobx-state-tree"
import * as views from './AssetViews'
import * as actions from './AssetActions'

const Asset = types
  .model('Asset', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    fileSize: types.maybeNull(types.number),
    fileType: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    lowResUrl: types.maybeNull(types.string),
    mimetype: types.maybeNull(types.string),
    maskUrl: types.maybeNull(types.string),
    type: types.maybeNull(types.string),

    // uploading
    loading: false,
    error: types.maybeNull(types.string),
    progress: 0
  })
  .views(views.assetViews)
  .actions(actions.assetActions)

export default Asset