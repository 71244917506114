export const subscriptionStoreActions = self => ({  

  async paywall() {
    try {
      self.store.set('loadingScreen', true)
      const res = await self.accountStore.getSubscriptionStatus()
      if (res.data.data.active === false) {
        self.accountStore.getCheckoutSession(self.queryStore.currentPrice)
      }
      self.store.set('loadingScreen', false)
    } catch (err) {
      self.store.set('loadingScreen', false)
    }
  },

  set(key, value) {
    self[key] = value
  }
})