import { types } from "mobx-state-tree"
import * as views from './PlanViews'
import * as actions from './PlanActions'

const Plan = types
  .model('Plan', {
    id: types.maybeNull(types.string),
    price: types.maybeNull(types.number),
    currency: types.maybeNull(types.string),
    tokens: types.maybeNull(types.number),
    freeTrial: types.maybeNull(
      types.optional(types.boolean, false)
    ),
    features: types.optional(types.frozen(), [])
  })
  .views(views.planViews)
  .actions(actions.planActions)

export default Plan