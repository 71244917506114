import axios from "axios"
import { API } from "../constants/system"
import { _throw } from "../utils/error-service"

export const api_post_image = async (data, token) => {
  try {
    const res = await axios.post(`${API}/run-animate`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_animate = async (data, token) => {
  try {
    const res = await axios.post(`${API}/animate`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res

  } catch (err) {
    return _throw(err)
  }
}