import * as React from 'react';
import { STRIPE_PUBLISHABLE_KEY, STRIPE_PRICING_TABLE_ID } from '../../constants/system';
import styled from '@emotion/styled';



function PricingPage(userData) {
  // Paste the stripe-pricing-table snippet in your React component


  return (
    // <stripe-pricing-table
    //   pricing-table-id={STRIPE_PRICING_TABLE_ID}
    //   publishable-key={STRIPE_PUBLISHABLE_KEY}
    //   //customer-email={userData.customerEmail}
    //   customer-session-client-secret={userData.clientSecret}

    // >
    // </stripe-pricing-table>

    <Wrapper>
      <Images>
        {/* <Image src={pricing_1} /> */}
      </Images>


    </Wrapper>
  );
}

export default PricingPage;


const Wrapper = styled.div`

`

const Images = styled.div`
  display: flex;
`

const Image = styled.img`

`