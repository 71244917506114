import { TABS } from "../../../constants/status"

export const guiStoreActions = self => ({  
  launchOnboarding() {
    if (self.allowOnboarding) 
      self.openUploadOnboardingModal()
  },
  openPresetsModal() {
    self.set('presetsModal', true)
  },
  closePresetsModal() {
    self.set('presetsModal', false)
  },
  openUploadModal() {
    self.set('uploadModal', true)
  },
  closeUploadModal() {
    self.set('uploadModal', false)
  },
  openUploadOnboardingModal() {
    self.set('uploadOnboardingModal', true)
  },
  closeUploadOnboardingModal() {
    self.set('uploadOnboardingModal', false)
  },
  openPaywallModal(title=null) {
    if (title) self.set('paywallTitle', title)
    else self.set('paywallTitle', null)
    self.set('paywallModal', true)
  },
  closePaywallModal() {
    self.set('paywallModal', false)
  },
  openGeneratingModal() {
    self.set('generatingModal', true)
  },
  closeGeneratingModal() {
    self.set('generatingModal', false)
  },
  openMergeAccountsModal() {
    self.set('mergeAccountsModal', true)
  },
  closeMergeAccountsModal() {
    self.set('mergeAccountsModal', false)
  },
  openProductModal(product) {
    self.set('productModalItem', product.id)
    product.set('tempType', product.productType)
    self.set('productModal', true)
  },
  closeProductModal() {
    self.set('productModal', false)
  },
  openUploadInfoModal() {
    self.set('uploadInfoModal', true)
  },
  closeUploadInfoModal() {
    self.set('uploadInfoModal', false)
  },
  openTokensModal() {
    self.set('tokensModal', true)
  },
  closeTokensModal() {
    self.set('tokensModal', false)
  },
  openCheckoutModal() {
    self.set('checkoutModal', true)
  },
  closeCheckoutModal() {
    self.set('checkoutModal', false)
  },
  openProductsModal() {
    self.set('productsModal', true)
  },
  closeProductsModal() {
    self.set('productsModal', false)
  },
  openSettingsModal() {
    self.set('settingsModal', true)
  },
  closeSettingsModal() {
    self.set('settingsModal', false)
  },
  openHelpModal() {
    self.set('helpModal', true)
  },
  closeHelpModal() {
    self.set('helpModal', false)
  },
  openAnimateModal(job) {
    self.set('extraData', { job })
    self.set('animateModal', true)
  },
  closeAnimateModal() {
    self.set('animateModal', false)
  },
  openSavePresetModal(jobId) {
    self.set('extraData', {
      jobId
    })
    self.set('savePresetModal', true)
  },
  closeSavePresetModal() {
    self.set('savePresetModal', false)
  },
  openEditTemplateModal() {
    self.set('editTemplateModal', true)
  },
  closeEditTemplateModal() {
    self.set('editTemplateModal', false)
  },
  openProductSettingsModal() {
    self.set('productSettingsModal', true)
  },
  closeProductSettingsModal() {
    self.set('productSettingsModal', false)
  },
  setGlowBorder() {
    self.set('glowBorder', true)
    setTimeout(() => {
      self.set('glowBorder', false)
    }, 2500)
  },
  setTemplateGlow() {
    self.set('templateGlow', true)
    setTimeout(() => {
      self.set('templateGlow', false)
    }, 2000)
  },
  closeTabs() {
    self.set('selectedTab', TABS.NONE)
  },
  set(key, value) {
    self[key] = value
  }
})