import { types } from "mobx-state-tree"
import * as actions from './presetsFactoryActions'
import * as views from './presetsFactoryViews'
import Preset from "../../models/preset/Preset"


const PresetsFactory = types
  .model('PresetsFactory', {
    presets: types.optional(
      types.array(Preset), []
    )
  })
  .views(views.presetsFactoryViews)
  .actions(actions.presetsFactoryActions)

export default PresetsFactory