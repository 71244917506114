
export const presetViews = self => ({
  get presetTitle() {
    if (self.title === null) {
      return ''
    } else {
      return self.title
    }
  },
  // get src() {
  //   if (!self.originUrl) return self.localUrl
  //   else return self.originUrl
  // },
})