import { types } from 'mobx-state-tree'
import * as views from './queryStoreViews'
import * as actions from './queryStoreActions'

const QueryStore = types
  .model('queryStore', {
    enterprise: types.maybeNull(types.boolean),
    selectedCategory: types.maybeNull(types.number),
    selectedPreset: types.maybeNull(types.number),
    currentPrice: types.maybeNull(types.string)
  })
  .views(views.queryStoreViews)
  .actions(actions.queryStoreActions)

export default QueryStore