import { getRoot } from "mobx-state-tree"

export const settingsStoreViews = self => ({
  get accountStore() {
    return getRoot(self).accountStore
  },
  get settings() {

    let loras = {}
    if (self.accountStore.isAdmin) {
      loras = { loras: self.selectedLoras }
    }

    return {
      fixedSeed: self.fixedSeed,
      aspectRatio: self.aspectRatio,
      productSize: self.productSize,
      floorLevel: self.floorLevel,
      numberOfImages: self.numberOfImages,
      model: self.model,
      ...loras
    }
  },
  get isComfyWorkflow() {
    const selected = self.models?.find(m => m.id === self.model)

    if (selected?.type === "COMFY") return true
  },
  get animateWorkflow() {
    const animateWorkflow = self.models?.find(m => m.id === "animatron")
    return animateWorkflow
  }
}) 