import { types } from "mobx-state-tree"
import * as views from './PresetViews'
import * as actions from './PresetActions'
import Asset from "../asset/Asset"
import Category from "../category/Category"
import Product from "../product/Product"

const Preset = types
  .model('Preset', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string),
    cover: types.maybeNull(
      types.reference(Asset)),
    subscriptionOnly: types.optional(types.boolean, true),
    category: types.maybeNull(
      Category
    ),
    loras: types.maybeNull(types.array(types.string)),
    type: types.maybeNull(types.string),
    defaultProduct: types.maybeNull(types.reference(Product)),
    prompt: types.maybeNull(types.string)
  })
  .views(views.presetViews)
  .actions(actions.presetActions)

export default Preset