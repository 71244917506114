import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_post_image = async (data, token) => {
  try {
    const res = await axios.post(`${API}/generate`, data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_prompts = async (token) => {
  try {
    const res = await axios.get(`https://api.airtable.com/v0/apprxuVkvJm2CEE3J/prompts`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

