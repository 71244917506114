import find from 'lodash/find'
import { toJS } from 'mobx'
import { _add_update_objects } from '../../../services/factories-service'

export const productsFactoryActions = self => ({
  addUpdateProducts(products) {
    return _add_update_objects(products, self.products, self.parseProduct)
  },
  addUpdateProduct(p) {
    const ids = self.addUpdateProducts([p])
    return ids[0]
  },
  findProduct(id) {
    return find(self.products, { id })
  },
  parseProduct(p) {
    return ({
      ...p,
      asset: p.asset?.id ? self.assetsFactory.addUpdateAsset(p.asset) : null,
    })
  },
})