import { types } from "mobx-state-tree"
import * as actions from './jobsFactoryActions'
import * as views from './jobsFactoryViews'
import Job from "../../models/job/Job"


const JobsFactory = types
  .model('JobsFactory', {
    jobs: types.optional(
      types.array(Job), []
    )
  })
  .views(views.jobsFactoryViews)
  .actions(actions.jobsFactoryActions)

export default JobsFactory