import { getRoot } from "mobx-state-tree"


export const planViews = self => ({
  get formattedPrice() {
    return `${self.getCurrency}${(self.price/100).toFixed(2)}`
  },
  get getCurrency() {
    if (self.currency === 'usd') return '$'
    if (self.currency === 'eur') return '€'
    if (self.currency === 'aed') return "AED "
    else return ""
  },
  get accountStore() {
    return getRoot(self).accountStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get featuresExist() {
    return self.features && Object.keys(self.features).length > 0
  },
  get formattedHigher() {
    const formerPrice = parseInt(self.price * 3/100)

    if (self.currency === 'aed') 
      return `${self.getCurrency}${(formerPrice)}`
    else 
      return `${self.getCurrency}${((formerPrice) + 0.99).toFixed(2)}`
  }
})