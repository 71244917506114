import { getRoot } from "mobx-state-tree"

export const subscriptionStoreViews = self => ({
  get accountStore() {
    return getRoot(self).accountStore
  },
  get queryStore() {
    return getRoot(self).queryStore
  },
  get store() {
    return getRoot(self)
  }
})