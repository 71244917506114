import find from 'lodash/find'
import { toJS } from 'mobx'
import { _add_update_objects } from '../../../services/factories-service'

export const assetsFactoryActions = self => ({
  addUpdateAssets(assets) {
    return _add_update_objects(assets, self.assets, self.parseAsset)
  },
  addUpdateAsset(p) {
    const ids = self.addUpdateAssets([p])
    return ids[0]
  },
  parseAsset(p) {
    return ({
      ...p,
    })
  },
})