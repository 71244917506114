import mixpanel from 'mixpanel-browser';
import { ENV } from '../constants/system';
import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";


export const _init_tracking_services = () => {
  if (ENV === "dev") return null

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: false, track_pageview: true, persistence: 'localStorage' });
  Hotjar.init(3637839, 6, {
    debug: false  
  });
  ReactGA.initialize("GTM-PKXCFWGH");
}

export const _identify = (userId, email) => {

  if (ENV === "dev") return null

  mixpanel.identify(userId)
  Hotjar.identify(userId)

  // create mixpanel profile
  mixpanel.people.set({ $email: email })
}

export const _track = (event, data) => {

  if (ENV === "dev") return null

  mixpanel.track(event, data)
  Hotjar.event(event)

}