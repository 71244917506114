import { types } from "mobx-state-tree"
import * as views from './JobViews'
import * as actions from './JobActions'
import User from "../user/User"
import Preset from "../preset/Preset"
import Asset from "../asset/Asset"

const Job = types
  .model('Job', {
    id: types.identifierNumber,
    startedAt: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    runningTime: types.optional(types.number, 0),
    // userId: types.maybeNull(
    //   types.reference(User)),
    presetId: types.maybeNull(types.number),
    notifyMe: false,
    assetId: types.maybeNull(
      types.reference(Asset)),
    generatedAsset: types.maybeNull(
      types.reference(Asset)),
    upscaledAsset: types.maybeNull(
      types.reference(Asset)),
    upscaling: false,
    aspectRatio: types.maybeNull(types.string)
  })
  .views(views.jobViews)
  .actions(actions.jobActions)

export default Job