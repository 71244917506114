import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import useQuery from '../../hooks/useQuery';

const BaseLayout = ({ children }) => {

  // check for query

  const queries = useQuery()

  return <>{children || <Outlet />}</>;
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
