import { AppBar, Box, ButtonBase, Dialog, Fade, IconButton, ImageList, ImageListItem, ImageListItemBar, Modal, Slide, Toolbar, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import SelectableImageList from '../Images/SelectableImageList';
import styled from '@emotion/styled';
import Button from '../Button/Button'
import { inject, observer } from 'mobx-react';
import { CheckCircle } from '@mui/icons-material';
import Preset from '../Preset/Preset';
import Checkout from '../Checkout/Checkout';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CheckoutModal = (props) => {

  const { accountStore, guiStore } = props.store

  const isMobile = useMediaQuery('(max-width:500px)');


  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      fullScreen={isMobile}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Wrapper isMobile={isMobile}>
       
        {isMobile ? <div style={{ height: '84px' }}/> : null}
        <HeaderWrap isMobile={isMobile}>
          <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: "100%" }}>
            <IconButton 
              onClick={() => {guiStore.closeCheckoutModal()}}
              aria-label="delete"
              sx={{     
                backgroundColor: '#404040',
                '&:hover': {
                  backgroundColor: '#595959', 
                }
              }}
                >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </Box>
        </HeaderWrap>
        {/* <Typography fontSize={"14px"} lineHeight={"22px"} sx={{ margin: "10px 0" }}>
          What would you like to see in the background? Presets help you generate images without a struggle of writing prompts.
        </Typography> */}


        <Checkout 
          clientSecret={accountStore.clientSecretStripe}
        />

      </Wrapper>
    </Dialog>
  )
}

export default inject('store')(observer(CheckoutModal))




const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #000;
  padding: 16px;
  width: 100%;
  border-radius: ${props => props.isMobile ? 0 : 16}px;
`

const HeaderWrap = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
  min-height: 50px;
  padding-bottom: 20px;
  min-width: 500px;

  ${props => props.isMobile ? `
    height: 96px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-width: 100%;
    padding: 16px;
    background-color: #252525;
    z-index: 100;
  ` : ''}
`