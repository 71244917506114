import { toJS } from "mobx"
import { getRoot } from "mobx-state-tree"
export const jobViews = self => ({
  get accountStore() {
    return getRoot(self).accountStore
  },
  get guiStore() {
    return getRoot(self).guiStore
  },
  get viewableUrl() {
    return "self.originUrl"
  },
  get src() {
    if (!self.originUrl) return self.localUrl
    else return self.originUrl
  },
  get showSorryForWaiting() {
    if (self.runningTime !== null ) {
      if (self.runningTime > 50){
        return true
      } else {
        return false
      }
    } else {
      return false
    }
    
  },
})