
export const assetViews = self => ({
  get viewableUrl() {
    return self.originUrl
  },
  get src() {
    return self.lowResUrl || self.url
  },
  get isVideo() {
    return /\.mp4(\?.*)?$/.test(self.url);
  }
})