import { types } from 'mobx-state-tree'
import * as views from './subscriptionStoreViews'
import * as actions from './subscriptionStoreActions'

const SubscriptionStore = types
  .model('subscriptionStore', {

  })
  .views(views.subscriptionStoreViews)
  .actions(actions.subscriptionStoreActions)

export default SubscriptionStore