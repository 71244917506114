import { types } from "mobx-state-tree"
import * as actions from './productsFactoryActions'
import * as views from './productsFactoryViews'
import Product from "../../models/product/Product"

const ProductsFactory = types
  .model('ProductsFactory', {
    products: types.optional(
      types.array(Product), []
    )
  })
  .views(views.productsFactoryViews)
  .actions(actions.productsFactoryActions)

export default ProductsFactory