import { useEffect, useState } from 'react';

function useQuery() {
  const [query, setQuery] = useState(null);

  useEffect(() => {
    // Parse the query string
    const searchParams = new URLSearchParams(window.location.search);

    // Convert the searchParams to an object
    const queryObj = {};
    for (let [key, value] of searchParams) {
      queryObj[key] = value;
    }

    // Update the state with the query object
    setQuery(queryObj);
  }, []);  // Empty array means this useEffect runs once, similar to componentDidMount

  return query;
}

export default useQuery;