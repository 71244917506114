import { types } from 'mobx-state-tree'
import * as views from './settingsStoreViews'
import * as actions from './settingsStoreActions'

const SettingsStore = types
  .model('settingsStore', {
    fixedSeed: false,
    aspectRatio: types.optional(
      types.enumeration('aspectRatio', ['PORTRAIT', 'LANDSCAPE', 'SQUARE']), 'SQUARE'
    ),
    productSize: types.optional(types.number, 2),
    floorLevel: types.optional(types.number, 15),
    numberOfImages: types.optional(types.number, 1),
    model: types.maybeNull(types.string),

    models: types.frozen(),

    uploadCutout: false,

    loras: types.frozen(),
    selectedLoras: types.array(types.string),
  })
  .views(views.settingsStoreViews)
  .actions(actions.settingsStoreActions)

export default SettingsStore