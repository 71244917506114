import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0Provider } from '@auth0/auth0-react';
import { API, PUBLIC_URL, AUTH0_CLIENT_ID, AUTH0_DOMAIN, ENV, AIRTABLE_ACCESS_TOKEN, STRIPE_PRICING_TABLE_ID, STRIPE_PUBLISHABLE_KEY, APPLE_REDIRECT_URI, APPLE_CLIENT_ID, GOOGLE_API_KEY, GOOGLE_CLIENT_ID, FACEBOOK_APP_ID } from './constants/system';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: PUBLIC_URL,
      audience: API,
      scope: "read:users"
    }}
    >
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
