import { _throw } from "../../../utils/error-service"

export const categoryActions = self => ({

  update(preset) {
    self.set('id', preset.id)
    self.set('title', preset.title)
  },
  set(key, value) {
    self[key] = value
  }
})