import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    }
  },
  palette: {
    primary: {
      main: "#E0DBDB",
    },
    secondary: {
      main: '#FFF',
    },
    black: {
      main: '#000000',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "42px",
          '&:disabled': {
            backgroundColor: 'grey',
            cursor: 'not-allowed',
            // You can also customize other styles for disabled state here
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: "#e0dbdb",
          },
          '&.Mui-disabled': {
            color: '#fff', // Text color for disabled state
            cursor: 'not-allowed', // Cursor for disabled input
            "&.MuiFromLabel-root": {
              color: '#fff !important', // Label text color for disabled state
            },
            ".MuiFilledInput-root": {
              backgroundColor: "#4F4F4F",
            },
            "& .MuiInputBase-input": {
              color: '#fff', // Input text color when disabled
              "-webkit-text-fill-color": '#fff',
              cursor: 'not-allowed', // Cursor for disabled input
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#fff !important",
          "& .Mui-disabled": {
            color: "#fff"
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          
          color: "white"
        },
        colorPrimary: {
          "&.Mui-checked": {
        
            color: "white"
          }
        },
        track: {
          opacity: 0.2,
          backgroundColor: "white",
          ".Mui-checked.Mui-checked + &": {
            opacity: 0.7,
            backgroundColor: "#d2d2d1"
          }
        }
      }
    },
    MuiCssBaseline: {
        styleOverrides: {
          '*': {
            '::-webkit-scrollbar': {
              width: '0px',
              background: 'transparent',
            },
            // 'scrollbar-width': 'none',
            scrollbarWidth: 'none',
            // '-ms-overflow-style': 'none',
            msOverflowStyle: 'none',
          },
        },
      },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroudColor: "red",
          '.MuiFormControl-root': {
            backgroundColor: '#4F4F4F',
            borderRadius: '16px',
            overflow: 'hidden',
            borderBottom: "none"
          }
        },
        inputRoot: {
        },
        listbox: {
          backgroundColor: '#313131',
          color: "#fff",
          '.MuiAutocomplete-option': {
            '&.Mui-focused': {
              backgroundColor: '#1c1b1b',
            },
            '&[aria-selected="true"]': {
              backgroundColor: '#1c1b1b',
            },
            '&.Mui-focused[aria-selected="true"]': {
              backgroundColor: '#1c1b1b',
            },
          },
        },
      }
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            // Add your hover styles here
            // opacity: 0.6,
            backgroundColor: "inherit"
            // Add any other hover styles you want
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Roboto, Syne', 
    h1: {  
        fontFamily: 'Syne',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '18px',
        fontStyle: 'normal',
        letterSpacing: '0.16px',
        fontFeatureSettings: "'clig' off, 'liga' off",
        color: '#FFF',
    },
    h2: {  
      fontFamily: 'Syne',
      fontWeight: 700,
      fontSize: 24,
      lineHeight: '18px',
      fontStyle: 'normal',
      letterSpacing: '0.16px',
      fontFeatureSettings: "'clig' off, 'liga' off",
      color: '#FFF',
      '@media (max-width:600px)': {
        fontSize: 16,
      }
  },
    body1: {  
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '27px',
        color: '#E0DBDB', 
    },
    body2: {  
      color: "#C6C6C6",
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "12px",
      letterSpacing: "0.15px"
  },
  },
});

export default theme;
