import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import Loader from '../../../components/Loader/loader';
import { useEffect } from 'react';

const LoginWrapper = styled(Box)(({ open }) => ({
  flexGrow: 1,
  backgroundColor: '#171717',
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  justifyItems: "center",
  position: "fixed",
  left: 0,
  top: 0,
  width: "100%",
  zIndex: open ? 99999 : -1,
  opacity: open ? 1 : 0,
  transition: 'opacity 0.3s 1s ease-in-out, z-index 0s 1.3s linear',
}));

function LoaderPage(props) {

  return (
      <LoginWrapper
        open={props.loadingScreen}
      >
        <Helmet>
          <title>ProductAi Studio</title>
        </Helmet>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
          {/* <p style={{ color: 'white' }}>{props.children}</p> */}
          <Loader></Loader>
        </Box>
      </LoginWrapper>
  );
}

export default LoaderPage;
