import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedOnlyIsLogedIn = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { accountStore } = props.store

  if (!isAuthenticated) return <Navigate to="/login" replace={true} />

  return props.children
}

export default inject('store')(observer(ProtectedOnlyIsLogedIn))


