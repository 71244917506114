import { useAuth0 } from "@auth0/auth0-react";
import Intercom from "@intercom/messenger-js-sdk";
import { inject, observer } from "mobx-react"

const IntercomMessenger = (props) => {

  const {accountStore} = props.store
  const { isAuthenticated  } = useAuth0();


  if (!isAuthenticated || !accountStore.userId || !accountStore.intercomHash) return null

  return (

    Intercom({
      app_id: 's60z7inj',
      user_id: accountStore.userId,
      email: accountStore.email,
      user_hash: accountStore.intercomHash
    })
  

  )
}

export default inject('store')(observer(IntercomMessenger))