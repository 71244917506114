import { Box, Fade, Modal, Typography, IconButton, Button } from '@mui/material'
import React from 'react'
import { useEffect } from 'react';
import SelectableImageList from '../Images/SelectableImageList';
import styled from '@emotion/styled';
import { inject, observer } from 'mobx-react';
import PricingPage from '../Stripe/PricingPage';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  maxWidth: 580,
  bgcolor: '#252525',
  boxShadow: 24,
  borderRadius: '16px'
};


const MergeAccountsModal = (props) => {

  const { guiStore, accountStore } = props.store

  function onMerge(userId) {
    accountStore.mergeAccounts(userId)
  }

  return (  
    <Modal
      open={props.open}
      onClose={props.onClose}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <Box paddingLeft={"16px"} paddingRight={"16px"} paddingTop={"19px"} paddingBottom={"34px"}>
            <Box display={"flex"} width={"100%"} justifyContent={"space-between"} gap={"5px"}>
              <Box paddingTop={"17px"}>
                <Typography style={{ lineHeight: "32px" }} id="modal-modal-title" variant="h1" component="h1">

                  An account with this email address already exists.

                </Typography>
              </Box>
              <Box>
                <IconButton 
                  onClick={() => {props.onClose()}}
                  aria-label="delete"
                  sx={{     
                    backgroundColor: '#404040',
                    '&:hover': {
                      backgroundColor: '#595959', 
                    }
                  }}
                    >
                  <CloseIcon style={{ color: 'white' }} />
                </IconButton>
              </Box>
            </Box>

            <Typography style={{ lineHeight: '17px' }} id="modal-modal-description" sx={{ mt: 2 }}>
                  It's smart if you join the two accounts. That way you won't lose any data and you will be able to login with both methods. 
            </Typography>

            <hr style={{ marginTop: '40px', marginBottom: '10px' }} />

            {
              accountStore.duplicates?.map(duplicate => (
                <Row key={duplicate.user_id}>
                  <Typography>{duplicate.email}</Typography>
                  <Button
                    id="merge-accounts"
                    sx={{
                      color: 'white',
                      "&.MuiButton-root": {
                        backgroundColor: "#5A3FF5",  
                          "&:hover": {
                            backgroundColor: "#6f52ff",  
                          }
                      },
                      fontStyle: "normal",
                      fontWeight: "700",
                      fontSize: '11px',
                      textTransform: 'none',
                      fontFamily: 'Source Code Pro',
                      letterSpacing: "0.4px",
                      borderRadius: "40px"
                      
                    }}
                    onClick={() => onMerge(duplicate.user_id)}
                  >Merge accounts</Button>
                </Row>
              ))
            }
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default inject('store')(observer(MergeAccountsModal))
