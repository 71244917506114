import styled from '@emotion/styled'
import React from 'react'

const ErrorInfo = () => {
  return (
    <Wrapper>
      <Title>Something went wrong.</Title>
    </Wrapper>
  )
}

export default ErrorInfo

const Wrapper = styled.div`
  padding: 20px;
`

const Title = styled.h1`
  color: #fff;
`