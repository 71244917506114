import { types } from "mobx-state-tree"
import * as views from './CategoryViews'
import * as actions from './CategoryActions'

const Category = types
  .model('Category', {
    id: types.identifierNumber,
    title: types.maybeNull(types.string)
  })
  .views(views.categoryViews)
  .actions(actions.categoryActions)

export default Category