import { getRoot } from "mobx-state-tree"
import { toJS } from "mobx"
import { USER_ROLES } from "../../../constants/status"
export const accountStoreViews = self => ({
  get guiStore() {
    return getRoot(self).guiStore
  },
  get productsFactory() {
    return getRoot(self).productsFactory
  },
  get settingsStore() {
    return getRoot(self).settingsStore
  },
  get isEnterprise() {
    return self.plan === "ENTERPRISE"
  },
  get isAdmin() {
    return self.role === USER_ROLES.ADMIN
  },
  get isPro() {
    if (self.isEnterprise) return true
    if (self.role === USER_ROLES.ADMIN) return true
    return self.plan === "PRO"
  }
})
