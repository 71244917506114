import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

const Slct = (props) => {

  const id = `label-id-${Math.random().toString(36).substring(2, 15)}`

  return (
    <FormControl sx={{
      
    }} fullWidth>
      {props.label && <InputLabel
        id={id}
        sx={{
          top: "15px"
        }}
      >{props.label}</InputLabel>}
      <Select
        labelId={props.label ? id : null}
        id="demo-simple-select"
        value={props.value}
        label={props.label}
        onChange={props.onChange}
        sx={{
          color: "#fff",
          borderRadius: props.borderRadius ? props.borderRadius : "16px",
          backgroundColor: props.backgroundColor ? props.backgroundColor : "#4F4F4F",
          fontSize: props.fontSize ? props.fontSize : "14px",
          
          "& .MuiSelect-icon": { color: 'white' },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Removes border on focus
          },
          '& .MuiSelect-select': {
            // paddingTop: '23px', // Moves the selected text 5 pixels down
            // paddingBottom: "7.5px",
            padding: props.padding ? props.padding : "none"
            // Other styles can be added here if needed
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none', // Removes border on focus
          },
        }}
      >
        {
          props.options.map((option, index) => {
            return (
              <MenuItem 
                key={index} 
                value={option.value}
                sx={{
                  color: "black !important",
                }}
              >
                {option.label}
              </MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default Slct