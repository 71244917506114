export const PRESET_TABS = {
  PRIVATE: "PRIVATE",
  PUBLIC: "PUBLIC"
}

export const TABS = {
  PRODUCTS: "PRODUCTS",
  TEMPLATES: "TEMPLATES",
  SETTINGS: "SETTINGS",
  SCENE: "SCENE",
  NONE: "NONE",
  TRAIN: "TRAIN"
}

export const ASPECT_RATIO = {
  PORTRAIT: "PORTRAIT",
  LANDSCAPE: "LANDSCAPE",
  SQUARE: "SQUARE"
}

export const TOKEN_PRICES = {
  GENERATE: 1,
  UPSCALE: 1
}


export const USER_ROLES = {
  ADMIN: "ADMIN"
}

export const UPLOAD_TYPES = {
  REFERENCE: "REFERENCE"
}

export const CREDITS = {
  OUT_OF_TOKENS: "OUT_OF_TOKENS",
  PRODUCT: "PRODUCT",
}

export const PRODUCT_TYPES = {
  DEFAULT: "DEFAULT",
  AI: "AI"
}

export const PRESET_TYPES = {
  FINETUNED: "FINETUNED",
  BASE: "BASE"
}

export const INTERFACE_TYPES = {
  LIFESTYLE: "lifestyle",
  BACKGROUND: "background"
}