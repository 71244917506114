import { onSnapshot } from "mobx-state-tree"
import { api_get_loras } from "../../../queries/system";

export const settingsStoreActions = self => ({  
  afterCreate() {
    let previousSnapshot = null;

    // Set up the snapshot listener after the model instance is created
    onSnapshot(self, (currentSnapshot) => {
        if (previousSnapshot && previousSnapshot.aspectRatio !== currentSnapshot.aspectRatio) {
            // Perform actions based on the value change
            self.setFloorLevel(15)
        }
        if (previousSnapshot && previousSnapshot.productSize !== currentSnapshot.productSize) {

          if (currentSnapshot.productSize > 3) {
            self.setFloorLevel(5)
          } else {
            self.setFloorLevel(15)
          }

        }
        // Update the reference to the previous snapshot
        previousSnapshot = currentSnapshot;
    });
  },
  async getLoras() {
    if (self.accountStore.isAdmin) {
      try {
        const res = await api_get_loras(self.accountStore.auth0AccessToken)

        if (res.error) throw res

        self.set('loras', res.data.data)
        
      } catch (err) {
        console.log(err)
        return err
      }
    } else {
    }
  },
  toggleFixedSeed() {
    self.set('fixedSeed', !self.fixedSeed)
  },
  setProductSize(value) {
    self.set('productSize', value)
  },
  setFloorLevel(value) {
    self.set('floorLevel', value)
  },
  set(key, value) {
    self[key] = value
  }
})