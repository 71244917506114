import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_get_jobs = async (token, ids = [], page) => {
  try {

    let pagination = ''
    let idsQuery = ''
    if (ids.length > 0) {
      idsQuery = `?ids=${ids.join(',')}`
    } else {
      if (page) {
        pagination = `?page=${page}`
      }
    }

    const res = await axios.get(`${API}/jobs${pagination}${idsQuery}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_upscale_asset = async (token, jobId) => {
  try {
    const res = await axios.post(`${API}/upscale`, {
      jobId
    }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    })

    return res
  } catch (err) {
    return _throw(err) 
  }
}