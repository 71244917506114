import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth0 } from "@auth0/auth0-react";
import LoaderPage from '../content/pages/LoaderPage/LoaderPage';

const ProtectedOnlyCustomer = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { accountStore, queryStore } = props.store

  if (!isAuthenticated) return <Navigate to="/signup" replace={true} />
  if (
    isAuthenticated && 
    accountStore.hasActiveStripeSubsciption === false
  ) {
    if (queryStore.enterprise)
      return <Navigate to="/enterprise" replace={true} />
    else
      return <Navigate to="/welcome" replace={true} />
  }


  return props.children
}

export default inject('store')(observer(ProtectedOnlyCustomer))


