import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_get_models = async (token) => {
  try {
    const res = await axios.get(`${API}/models`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_get_loras = async (token) => {
  try {
    const res = await axios.get(`${API}/loras`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  }
  catch (err) {
    return _throw(err)
  }
}