import { getRoot } from "mobx-state-tree"
import { INTERFACE_TYPES } from "../../../constants/status"

export const guiStoreViews = self => ({
  get accountStore() {
    return getRoot(self).accountStore
  },
  get allowOnboarding() {
    return self.accountStore.plan !== "ENTERPRISE"
  },
  get trainingInterface() {
    return self.productType === INTERFACE_TYPES.LIFESTYLE
  }
})