import axios from "axios"
import { _throw } from "../utils/error-service"
import { API } from '../constants/system'

export const api_get_products = async (token) => {
  try {

    const res = await axios.get(`${API}/products`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}

export const api_update_product = async (token, product) => {
  try {

    const res = await axios.put(`${API}/product/${product.id}`, product, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    return res
  } catch (err) {
    return _throw(err)
  }
}


export const api_guess_product_type = async (token, productId) => {

  try {
    const res = await axios.get(`${API}/guess-product-type/${productId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    return res
  } catch (err) {
    return _throw(err)
  }

}