
import ImageList from '@mui/material/ImageList';
import React, { useState } from 'react';
import { inject, observer } from 'mobx-react'
import Preset from '../Preset/Preset';
import groupBy from 'lodash/groupBy'
import { Typography, useMediaQuery } from '@mui/material';
import { rearrangeObject } from '../../services/helpers-service';

const SelectableImageList = (props) => {

  const { generateImageStore, accountStore, queryStore } = props.store 

  let grouped = groupBy(props.images, i => i.category?.id)

  grouped = rearrangeObject(grouped, queryStore.selectedCategory)

  const presetsBreakPoint = useMediaQuery('(max-width:800px)');

  return (
      Object.keys(grouped).map(group => (
        <span key={group}>
          
          {
            <Typography sx={{
              fontFamily: "Syne",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              color: "#fff",
              paddingLeft: "16px"
            }}>{ grouped[group][0].category?.title ? grouped[group][0].category.title : props.notGrouped ? "" : "Others" }</Typography>
          }
          <ImageList cols={presetsBreakPoint ? 2 : 4} gap={7} sx={{
            paddingRight: "16px",
            paddingLeft: "16px",
            overflow: 'hidden',
            margin: "10px 0 20px",
            // '@media (max-width: 599px)': {
            //   display: 'flex',
            //   flexDirection: "row",
            //   marginTop: "0px",
            //   paddingRight: "10px",
            //   paddingLeft: "10px",
            //   overflowX: 'scroll'
            // },
          }}>
            {grouped[group].map((image) => {
              return (
                <Preset 
                  key={image.id}
                  id={image.id}
                  selectedIndex={generateImageStore.selectedIndex}
                  presetTitle={image.presetTitle}
                  cover={image.cover}
                  toggleImageSelection={id => props.toggleImageSelection(id)}
                  openPaywall={() => props.store.guiStore.openPaywallModal(`Gain access to ${image.presetTitle}`)}
                  subscribersOnly={image.subscribersOnly}
                />
              )
            })}
          </ImageList>
        </span>
    ))
  );
};

export default inject('store')(observer(SelectableImageList));
