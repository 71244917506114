import { Helmet } from 'react-helmet-async';
import PageHeader from '../../../components/Header/ApplicationHeader';
// import PageTitleWrapper from '../../../components/PageTitleWrapper';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import ApplicationHeader from '../../../components/Header/ApplicationHeader';
import PricingPage from '../../../components/Stripe/PricingPage';
import { inject, observer } from 'mobx-react'
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import pricing_1 from '../../../assets/pricing_1.png'
import pricing_2 from '../../../assets/pricing_2.png'
import pricing_3 from '../../../assets/pricing_3.png'
import pricing_4 from '../../../assets/pricing_4.png'
import pricing_5 from '../../../assets/pricing_5.png'
import pricing_6 from '../../../assets/pricing_6.png'
import MergeAccountsModal from '../../../components/Modals/MergeAccountsModal';
import useQuery from '../../../hooks/useQuery';
import CheckoutModal from '../../../components/Modals/CheckoutModal';


const PricingWrapper = styled(Box)({
  flexGrow: 1,
  backgroundColor: '#171717',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden'
});


function Enterprise(props) {

  const { accountStore, guiStore, queryStore } = props.store 
  const Navigate = useNavigate();
  const containerRef = useRef(null);

  const queries = useQuery()

  useEffect(() => {

    const container = containerRef.current;
    const containerWidth = container.offsetWidth;
    const contentWidth = container.scrollWidth;
    const initialScroll = (contentWidth - containerWidth) / 2;

    container.scrollLeft = initialScroll;

    // accountStore.getPricingTable()
    if (accountStore.hasActiveStripeSubsciption === true) {
      Navigate('/dashboard/homebase')
    }
  }, [])

  useEffect(() => {
    if (queries?.priceId) {
      accountStore.getCheckoutSession(queries.priceId)
    }
  }, [ queries ])

  const isMobile = useMediaQuery('(max-width:500px)');

  function onClick() {
    guiStore.openCheckoutModal()
  }

  return (
    <>
      <Helmet>
        <title>Welcome to ProductAI</title>
      </Helmet>
      <PricingWrapper>
        <ApplicationHeader />
        {/* { accountStore.hasActiveStripeSubsciption === false &&
          <Box display={"flex"} justifyContent={"center"}  sx={{ backgroundColor: '#171717', lineHeight: '28px'}} padding={"20px"}>
            <Typography variant='h1' align='center' sx={{ color: 'white'}}>Enjoy unlimited product photos. Cancel anytime.</Typography>
          </Box>
        } */}


        <Outer isMobile={isMobile}>
          <Images ref={containerRef}>

            <Image width={isMobile ? "30%" : "155px"} src={pricing_1} />
            <Image width={isMobile ? "30%" : "155px"} src={pricing_2} />
            <Image width={isMobile ? "30%" : "155px"} src={pricing_3} />
            <Image width={isMobile ? "30%" : "155px"} src={pricing_4} />
            <Image width={isMobile ? "30%" : "155px"} src={pricing_5} />
            <Image width={isMobile ? "30%" : "155px"} src={pricing_6} />
            
          </Images>
        </Outer>

        <Info>

          <Typography style={{ fontSize: "32px" }} id="modal-modal-title" variant="h1" component="h1">
            Welcome to better product photos!
          </Typography>
          <Typography style={{fontSize: "14px", margin: "10px 0"}}>
            We are excited to have you here. Let's boost your sales with a makeover of your product shots.
          </Typography>

          <PriceInfo onClick={onClick}>
            <Tag>ENTERPRISE</Tag>
            <Typography style={{ fontSize: "24px", letterSpacing: "0.25px", lineHeight: "32px" }} variant="h1" component="h1">
              $499/month
            </Typography>
            {/* <Typography component="p" style={{ fontSize: "18px", letterSpacing: "0.25px", fontFamily: "Syne", opacity: 0.5 }}>
            then $499/month
            </Typography> */}
          </PriceInfo>

            <Buttons>
              <Button 
                variant="contained" 
                fullWidth
                color="primary" 
                size='small'
                startIcon={null} 
                disabled={!accountStore.checkoutSessionUrl && !accountStore.clientSecretStripe}
                onClick={onClick}
                sx={{
                    maxWidth: isMobile ? "225px" : "275px",
                    width: "100%",
                    height: isMobile ? "53px" : "66px",
                    borderRadius: '100px',
                    textTransform: 'none',
                    backgroundColor: "#5A3FF5",
                    fontFamily: "Syne",
                    fontSize: isMobile ? "20px" : "24px", 
                    color: "white",
                    "&:hover": {
                        backgroundColor: "#6f52ff",
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "gray",  // set the color to gray when it's loading/disabled
                    }
                }}
            >
                Continue
            </Button>

            <Typography component="p" style={{ fontSize: "14px", letterSpacing: "0.1px" }}>
              100% Money-Back Guarantee
            </Typography>
          </Buttons>

        </Info>

        {/* <PricingPage 
          clientReferenceId={accountStore.auth0Id}
          customerEmail={accountStore.email}
          clientSecret={accountStore.pricingTableClientSecret}
        /> */}
      </PricingWrapper>
      <MergeAccountsModal 
        open={guiStore.mergeAccountsModal}
        onClose={() => guiStore.closeMergeAccountsModal()}
      />
      <CheckoutModal 
        open={guiStore.checkoutModal}
        onClose={() => guiStore.closeCheckoutModal()}
      />
    </>
  );
}

export default inject('store')(observer(Enterprise))


const Outer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.isMobile ? "60px 0" : "100px 0 60px"};
`

const Images = styled.div`
    overflow-x: scroll;
    display: inline-block;
    white-space: nowrap;  // This keeps the content in a single row
`;

const Image = styled.img`
  margin: 0 10px; 
  border-radius: 16px;
`

const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const PriceInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 35px 0;
`
const Tag = styled.div`
  border-radius: 7px;
  background: #FEDEAE;
  font-family: Syne;
  font-size: 16px;
  color: #000;
  width: 200px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 5px;
`

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
`

