export function setQueryStrings(queryObject, set) {

  if (queryObject?.enterprise)
    set('enterprise', queryObject?.enterprise === "true")
  else set('enterprise', false)

  if (queryObject?.category) {
    set('selectedCategory', parseInt(queryObject?.category))
  } else {
    set('selectedCategory', null)
  }  

  if (queryObject?.preset) {
    set('selectedPreset', parseInt(queryObject?.preset))
  }

  if (queryObject?.price) {
    set('currentPrice', queryObject?.price)
  }

}