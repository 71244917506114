import axios from 'axios'
import { _throw } from "../../../utils/error-service"
import { API } from "../../../constants/system"
import { toJS } from 'mobx'

export const assetActions = self => ({

  update(asset) {
    self.set('id', asset.id)
    self.set('title', asset.title)
    self.set('fileSize', asset.fileSize)
    self.set('fileType', asset.fileType)
    //self.set('url', asset.originUrl)
    self.set('url', asset.url)
    self.set('mimetype', asset.mimetype)
    self.set('type', asset.uploadedType)
  },
  set(key, value) {
    self[key] = value
  }
})