import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import token from '../../assets/coin.png'

export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const billingClick = () => {
    props.billing()
    handleClose()
  }

  const logoutClick = () => {
    props.logout()
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem 
          sx={{
            color: "#171717",
            backgroundColor: "#",
            fontStyle: "italic"
          }}
          onClick={props.tokensLeft}
        >
          {props.tokens} tokens left
        </MenuItem> */}
        {
          props.isTrial && <>
            <MenuItem
              sx={{
                color: "#171717",
                fontStyle: "italic"
              }}
            >
              TRIAL
            </MenuItem>
          </>
        }
        <MenuItem 
          sx={{
            color: "#171717"
          }}
        onClick={billingClick}>Billing</MenuItem>
        <MenuItem 
          sx={{
            color: "#171717"
          }}
        onClick={logoutClick}>Logout</MenuItem>
      </Menu>
    </div>
  );
}