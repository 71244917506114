import { toJS } from "mobx"
export const UserActions = self => ({
  update(user) {
    if (user.email)self.set('email', user.email)
    if (user.firstName) self.set('firstName', user.firstName)
    if (user.lastName) self.set('lastName', user.lastName)
    if (user.hashId) self.set('hashId', user.hashId)
  },
  set(key, value) {
    self[key] = value
  }
})
