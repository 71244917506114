import { CREDITS } from "../../../constants/status";
import { api_get_trainings, api_train } from "../../../queries/train";

export const trainStoreActions = self => ({  

  async train(files) {
    try {
      self.set("loading", true)
      const formData = new FormData();

      // Append each file to the FormData object under the name 'files'
      
      formData.append('triggerWord', self.productContext);
      formData.append('steps', self.steps);
      files.forEach((fileData) => {
        formData.append('files', fileData.file);
      });

      const res = await api_train(self.accountStore.auth0AccessToken, formData) 

      if (res.error) throw res

      self.set("loading", false)

      return res

    } catch (err) {
      self.set("loading", false)
      if (err.response.data.message === CREDITS.OUT_OF_TOKENS) {
        self.set("launchTrainingOnMount", true)
        self.guiStore.openPaywallModal()
      }
      
    }
  },

  async getTrainings() {
    try {
      const res = await api_get_trainings(self.accountStore.auth0AccessToken)

      self.set('trainings', res.data.data.map((training) => ({
        ...training,
        product: self.productsFactory.addUpdateProduct(training.product)

      })))
    } catch (err) {
      console.log(err)
    }
  },

  set(key, value) {
    self[key] = value
  }

})

