import styled from '@emotion/styled'
import { CheckCircle, Lock } from '@mui/icons-material'
import { Box, ButtonBase, Fade, Icon, ImageListItem, ImageListItemBar, Typography } from '@mui/material'
import React from 'react'

const Preset = (props) => {
  return (
    <ButtonBase 
      key={props.id}
      TouchRippleProps={{ style: { transitionDuration: '4s' }}}
      sx={{
          '.MuiTouchRipple-root': { color: '#fff' },
        }}
      onClick={() => {
        if (props.subscribersOnly)
          props.openPaywall()
        else
          props.toggleImageSelection(props.id)
      }}>
      <ImageListItem 
        key={props.id} 
        sx={{
          position: 'relative',
          '@media (max-width: 599px)': {
            // width: '150px',  
          },
        }}
      >

        {props.selectedIndex === props.id &&
          <Fade 
            in={props.selectedIndex === props.id} 
            timeout={400}
          >
            <Box 
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
              }}
            >
              <CheckCircle 
                style={{ color: '#fff', filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))" }} 
              />
            </Box>
          </Fade>
        }
        <img
          src={props.cover?.src}
          alt={props.cover?.src}
          style={{
              cursor: 'pointer',
              border: '2px solid',
              borderColor: props.selectedIndex === props.id ? '#FFF' : 'transparent', 
              transition: 'border-color 0.3s ease-in-out'
            }}
        />
        <Box 
          component="div"
          sx={{
            position: 'absolute',
            bottom: '9px',
            left: '13px',
            color: '#FFF',
            fontFeatureSettings: "'clig' off, 'liga' off",
            textShadow: '0px 0px 4px #000',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '120%',
            letterSpacing: '0.4px',
            whiteSpace: 'normal',
            maxWidth: '75%',
            overflow: 'visible',
            textAlign: 'left',
            '@media (max-width: 599px)': { 
              maxWidth: '65%',
            },
          }}
        >
          
        </Box>
        {props.selectedIndex === props.id && (
          <Fade in={props.selectedIndex === props.id} timeout={400}>
            <ImageListItemBar
              sx={{
                  background: 'transparent',
                }}
            />
          </Fade>
        )}

        {
          props.subscribersOnly ? 
          <LockedWrapper>
            <LockWrap>
            <Lock
              style={{ color: "#FFF" }}
            />
            </LockWrap>
          </LockedWrapper> : null
        }
        <Typography
          sx={{
            textAlign: 'left',
            fontFamily: 'Open Sans',
            fontSize: '12px',
            lineHeight: '14px',
            fontStyle: 'normal',
            fontWeight: '700',
            marginTop: '3px'
          }}
        >{props.presetTitle}</Typography>
      </ImageListItem>
    </ButtonBase>
  )
}

export default Preset

const LockedWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LockWrap = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;
`